<template>
  <div id="root-component">
    <component
      v-if="rootComponent && rootComponent.name"
      :is="rootComponent.name"
      v-bind="rootComponent.props"
    />
    <div class="no-routing-wrapper" v-else>
      <LoadingDots />
    </div>
  </div>
</template>

<script>
import { SfLoader } from '@storefront-ui/vue';
import {
  defineComponent,
  useRoute,
  ssrRef,
  useContext,
  useFetch,
} from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@gemini/vsf-cache';
import { useUrlResolver } from '~/composables/useUrlResolver';
import cacheControl from '~/helpers/cacheControl';
import { isCmsPage } from '~/helpers/cms/cmsData';
import { LoadingDots } from '~/components/General';

function isRedirect(redirectCode) {
  const redirectCodes = new Set().add(301).add(302);
  return redirectCodes.has(redirectCode);
}

export default defineComponent({
  name: 'RoutePage',
  components: {
    SfLoader,
    LoadingDots,
    ProductPage: () => import('~/pages/Product.vue'),
    CategoryPage: () => import('~/pages/Category.vue'),
    CmsPage: () => import('~/pages/Page.vue'),
  },
  middleware: cacheControl({
    'max-age': 60,
    'stale-when-revalidate': 5,
  }),
  transition: 'fade',
  setup() {
    const CacheTagPrefixUrl = 'U'; // TODO move to cache lib
    const { error: nuxtError, redirect, app } = useContext();
    const route = useRoute();
    const {
      params: { path },
    } = route.value;
    const { result, search: resolveUrl } = useUrlResolver();
    const rootComponent = ssrRef(null);
    const { addTags } = useCache();

    useFetch(async () => {
      rootComponent.value = { name: '' };
      await resolveUrl();

      const component = { name: '' };
      const urlRewrite = result?.value;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const shouldRedirect = isRedirect(urlRewrite?.redirectCode);

      if (
        shouldRedirect &&
        urlRewrite?.urlPath &&
        urlRewrite?.urlPath !== path
      ) {
        const regx = /^(?:[a-z]+:)?\/\//i;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const redirectPath = regx.test(urlRewrite.urlPath)
          ? urlRewrite.urlPath
          : app.$fixUrlForCurrentLocale(
              `/${urlRewrite.urlPath.replace(/^(\/)/, '')}`
            );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        redirect(urlRewrite.redirectCode, redirectPath);
        return;
      }

      switch (urlRewrite?.entityCode) {
        case 'Product': {
          component.name = 'ProductPage';
          component.props = {
            productId: urlRewrite?.entityId,
          };
          break;
        }
        case 'ProductList': {
          component.name = 'CategoryPage';
          component.props = {
            categoryId: urlRewrite?.entityId,
          };
          break;
        }
        default: {
          if (isCmsPage(path)) {
            // TODO define a specific case once implemented DXP cms service
            component.name = 'CmsPage';
            component.props = {
              identifier: path,
            };
          } else {
            // FixMe: Using cms map to throw 404 before Page.vue is called
            component.name = 'Error';
            component.props = {
              identifier: path,
            };
          }
          break;
        }
      }

      if (!component?.name || component.name === 'Error') {
        nuxtError({ statusCode: 404 });
      } else {
        // avoid caching cms, the cache doesn't work with nuxtError
        // eslint-disable-next-line no-lonely-if
        if (component.name !== 'CmsPage') {
          addTags([
            { prefix: CacheTagPrefix.View, value: 'urlrewrite' },
            { prefix: CacheTagPrefixUrl, value: urlRewrite?.entityId },
          ]);
        }
      }
      rootComponent.value = component;
    });

    return {
      rootComponent,
    };
  },
});
</script>

<style lang="scss" scoped>
.no-routing-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 5.625rem);
  @include from-landscape-min {
    height: calc(100vh - 7.6875rem);
  }
}
</style>
